import { useEffect, useState } from "react";
import {storage} from "../firebase";
import {ref, listAll, getDownloadURL} from "firebase/storage";

import {motion} from "framer-motion";

import Header from "../components/Header";
import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";
import Tag from "../components/Tag";

import "../styles/contact.scss";

function Contact() {
    const [clicked, setClicked] = useState(false);
    const email = "emmal.stylist@gmail.com"
    const [imageList, setImageList] = useState([]);
    
    useEffect(() => {
        const imageFolder = ref(storage, "contact/");
        setImageList([]);
        listAll(imageFolder).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                });
            });
        });
    }, []);

    window.scrollTo(0, 0);

    const handleClick = () => {
        if (!clicked) navigator.clipboard.writeText(email);
        setClicked(!clicked);
    }

    return (
        <>
            <Header/>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                className="min-height"
            >
                <div className="back-img">
                    <GalleryImage url={imageList[0]}/>
                    <div
                        className="contact link-section full-height"
                    >
                        <div className="email">{email}</div>
                        <button
                            className={clicked ? "email-copy" : ""}
                            onClick={handleClick}
                        >
                            {clicked ? "copied!" : "copy email"}
                        </button>
                    </div>
                </div>
            </motion.div>
            <Footer/>
            <Tag />
        </>
    );
}
  
export default Contact;