import React from "react";

import "../styles/footer.scss";

const ContactComponent = () => {

  return (
    <div className="pre-footer">
      For more details on any project, please contact Emma directly.
      {/* <Link to={"/contact"} className="footer-button">contact</Link> */}
    </div>
  );
};

export default ContactComponent;
