import { useEffect, useState } from "react";
import {storage} from "../firebase";
import {ref, listAll, getDownloadURL} from "firebase/storage";

import {motion} from "framer-motion";

import Header from "../components/Header";
import Footer from "../components/Footer";
// import GalleryColumns from "../components/GalleryColumns";
import GalleryRows from "../components/GalleryRows";

import "../styles/gallery.scss";

function Gallery() {
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const imageFolder = ref(storage, "food/");
        setImageList([]);
        listAll(imageFolder).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                }).then(() => setLoading(false));
            });
        });
    }, []);

    return (
        <>
            <Header/>
            <motion.div
                className="page  min-height"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div className={loading ? "gallery waiting" : "gallery"}>
                    {loading ? (<span className="loader theme-color"></span>) : (
                        // <GalleryColumns imageList={imageList} />
                        <GalleryRows imageList={imageList} />
                    )}
                </div>
            </motion.div>
            <Footer/>
        </>
    );
}
  
export default Gallery;

// https://www.youtube.com/watch?v=YOAeBSCkArA