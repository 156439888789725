import { useEffect, useState } from "react";
import {storage} from "../firebase";
import {ref, listAll, getDownloadURL} from "firebase/storage";

import {motion} from "framer-motion";

import Header from "../components/Header";
import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";

import "../styles/about.scss";


function About() {
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const imageFolder = ref(storage, "about/");
        setImageList([]);
        listAll(imageFolder).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                }).then(() => setLoading(false));
            });
        });
    }, []);

    return (
        <>
            <Header/>
            <motion.div
                className={loading ? "about-image waiting min-height" : "about-image  min-height"}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <GalleryImage url={imageList[0]}/>
                <div className="about">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        <p>
                            Emma Lahaye is a London-based food & props stylist and production designer, bringing her creative eye to a number of books and television programmes. 
                        </p>
                        <p>
                            From recipe developing to art directing the final shots, Emma is celebrated for working closely with multiple publishers and production houses, such as Penguin, Headline, the BBC and ITV, throughout their projects.
                        </p>
                    </div>
                </div>
            </motion.div>
            <Footer/>
        </>
    );
}
  
export default About;