import GalleryImage from "./GalleryImage";

const GalleryRows = ({imageList}) => {

    return (
        <div className="gallery-row">
            {imageList.map((url, i) => {
                return <GalleryImage key={i} url={url}/>
            })}
        </div>
    );
};

export default GalleryRows;
