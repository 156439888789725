import { BrowserRouter } from "react-router-dom";
import { useStateContext } from "./context/StateContext";

import AnimatedRoutes from "./components/AnimatedRoutes";

import './App.css';
import "./styles/website.scss";
import "./styles/page.scss";

function App() {
  const { menu } = useStateContext();
  
  return (
    <div className={"App menu " + (menu && "open")}>
        <BrowserRouter>
            <AnimatedRoutes/>
        </BrowserRouter>
    </div>
  );
}

export default App;

// https://emmalahaye.com/emmalahaye/emmalahaye_home.html
