import { Route, Routes, useLocation } from "react-router-dom";

import Home from '../pages/Home';
import About from '../pages/About';
import Gallery from '../pages/Gallery';
import Contact from '../pages/Contact';
import Work from "../pages/Work";

import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {  
    const location = useLocation();
  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" exact={true} element={<Home />} />
            <Route path="/gallery" exact={true} element={<Gallery />} />
            <Route path="/about" exact={true} element={<About />} />
            <Route path="/contact" exact={true} element={<Contact />} />
            <Route path="/work" exact={true} element={<Work />} />
        </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;