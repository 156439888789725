import { useState } from "react";
import {motion} from "framer-motion";

import Header from "../components/Header";
import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";

import "../styles/work.scss";
import "../styles/switch.scss";

import tenDay from "../images/book/10day.jpeg";
import butter from "../images/book/butter.jpeg";
import desi from "../images/book/desi.jpeg";
import family from "../images/book/family.jpeg";
import fhh from "../images/book/fhh.jpeg";
import finding from "../images/book/finding.JPG";
import fry from "../images/book/fry.jpeg";
import grow from "../images/book/grow.jpeg";
import healthy from "../images/book/healthy.jpeg";
import household from "../images/book/household.jpeg";
import juiceman from "../images/book/juiceman.jpeg";
import meganrossi from "../images/book/megan-rossi.jpeg";
import menopausing from "../images/book/menopausing.jpeg";
import nadiya from "../images/book/nadiya.jpeg";
import newedit from "../images/book/new-edit.jpeg";
import organised from "../images/book/organised.jpeg";
import raymond from "../images/book/raymond.jpeg";
import school from "../images/book/school.jpg";
import skin from "../images/book/skin.jpeg";
import time from "../images/book/time.jpeg";
import weekend from "../images/book/weekend.jpg";
import selfcare from "../images/book/selfcare.jpeg";
import magpies from "../images/book/magpies.jpeg";
import tessdaly from "../images/book/tessdaly.jpeg";
import baby from "../images/book/baby.jpeg";

import nadiyatv from "../images/video/nadiyatv.jpg";
import nadiyatv2 from "../images/video/nadiyatv2.jpeg";
import money from "../images/video/money.jpeg";
import antique from "../images/video/antique.jpeg";
import ContactComponent from "../components/ContactComponent";

function Work() {
    const books = [ tenDay, butter, desi, family, fhh, finding, fry, grow, healthy, household, juiceman, meganrossi, menopausing, nadiya, newedit, organised, raymond, school, skin, time, weekend, selfcare, magpies, tessdaly, baby ];
    const shows = [ antique, nadiyatv, nadiyatv2, money ];

    const [toggle, setToggle] = useState(false);

    return (
        <>
            <Header/>
            <motion.div
                className="work  min-height"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <label className="switch">
                    <input type="checkbox"/>
                    <div className="slider" onClick={() => setToggle(!toggle)}>
                        <div className="">books</div>
                        <div className="">shows</div>
                    </div>
                    <div className="slider-card">
                        <div className="slider-card-face slider-card-front">books</div>
                        <div className="slider-card-face slider-card-back">shows</div>
                    </div>
                </label>
                <div className={!toggle ? "work-image" : "work-image shows"}>
                    {!toggle ? books.map((image, i) => {
                        return (
                            <GalleryImage url={image} key={i}/>
                        )
                    }) : shows.map((image, i) => {
                        return (
                            <GalleryImage url={image} key={i}/>
                        )
                    })}
                </div>
            </motion.div>
            <ContactComponent/>
            <Footer/>
        </>
    );
}
  
export default Work;