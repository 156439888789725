import { useState } from "react";

const GalleryImage = ({url}) => {
    const [imageLoading, setImageLoading] = useState(true);

    return (
        <div className={imageLoading ? "gallery-image wait-image" : "gallery-image"}>
            <img
                src={url} 
                alt="food"
                onLoad={() => setImageLoading(false)}
                // loading="lazy"
            />
        </div>
    );
};

export default GalleryImage;
