import { useEffect, useState } from "react";
import {storage} from "../firebase";
import {ref, listAll, getDownloadURL} from "firebase/storage";
import { Link } from "react-router-dom";

import {motion} from "framer-motion";

import "../styles/home.scss";
import GalleryImage from "../components/GalleryImage";

function Home() {
    const [width, setWidth] = useState(window.innerWidth);
    const [imageList, setImageList] = useState([]);
  
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    
    useEffect(() => {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);

    
    useEffect(() => {
        const imageFolder = ref(storage, "home/");
        setImageList([]);
        listAll(imageFolder).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                });
            });
        });
    }, []);


    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
        <Link to="/gallery" className="home">

        <div className="back-img">
            <GalleryImage url={imageList[0]}/>
            <div
                className="contact link-section full-height"
            >
                <h1>Emma Lahaye</h1>
                {width <= 950 ? (
                <h2>Food | Props | Set Design</h2>
                ) : (
                <h2>Stylist — Food | Props | Set Design</h2>
                )}
            </div>
        </div>
        {/* <div className="background-image" style={{background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${imageList[0]})`}}> */}
        {/* </div> */}
        </Link>
        </motion.div>
    );
}
  
export default Home;