import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Tag = () => {

  const [width, setWidth] = useState(window.innerWidth);
  
  function handleWindowSizeChange() {
    setWidth(prev => window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

    const tagStyle = {
            color: 'black',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            opacity: '0.3',
            padding: '25px',
            position: 'absolute',
            marginTop: '-70px',
            right: '0',
        };
        const tagStyleMobile = {
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                opacity: '0.3',
                padding: '25px',
                position: 'absolute',
                right: '0',
            };

    const site = "https://wooliston.com/";

  return (
    <Link to={site} style={width >= 950 ? tagStyle : tagStyleMobile}  target="_blank" rel="noopener noreferrer">
        <span>website built by wooliston.com</span>
    </Link>
  )
}

export default Tag