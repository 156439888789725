import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Instagram } from "../images/svg/instagram.svg";
// import { ReactComponent as Facebook } from "../images/svg/facebook.svg";
import { ReactComponent as Email } from "../images/svg/email.svg";

import "../styles/footer.scss";

const Footer = () => {

  return (
    <>
      <footer className="page-footer">
          <div className="socials">
              <Link to="https://instagram.com/emmalahayestylist?igshid=MzRlODBiNWFlZA=="><Instagram className="svg-size"/></Link>
              {/* <Link to="https://www.facebook.com/emmalahayestylist"><Facebook className="svg-size"/></Link> */}
              <Link to="/contact"><Email className="svg-size"/></Link>
          </div>
      </footer>
    </>
  );
};

export default Footer;

// #395D3996
// https://icons8.com/icons/set/instagram--green