import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../styles/header.scss";
import { useStateContext } from "../context/StateContext";

const Header = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const { menu, setMenu } = useStateContext();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <header className="page-header">
        <Link to={"/"} className="website-title">
            <h1>Emma Lahaye</h1>
            {width <= 950 ? (
              <h2>Food | Props | Set Design</h2>
            ) : (
              <h2>Stylist — Food | Props | Set Design</h2>
            )}
        </Link>
        <button className="menu-button" onClick={() => setMenu(!menu)}>{menu ? "Close" : "Menu"}</button>
        {width <= 950 ? (
          <nav onClick={() => setMenu(!menu)}>
            <Link to={"/gallery"}>Gallery</Link>
            <Link to={"/work"}>Books & TV</Link>
            <Link to={"/about"}>About</Link>
            <Link to={"/contact"}>Contact</Link>
          </nav>
        ) : (
          <nav>
            <Link to={"/gallery"}>Gallery</Link>
            <Link to={"/work"}>Books & TV</Link>
            <Link to={"/about"}>About</Link>
            <Link to={"/contact"}>Contact</Link>
          </nav>
        )}
      </header>
    </>
  );
};

export default Header;
