// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getStorage} from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-Z99ezta-Y7gozF1Zc91fzIjQxKa17Sw",
  authDomain: "emmalahaye-b7fb3.firebaseapp.com",
  projectId: "emmalahaye-b7fb3",
  storageBucket: "emmalahaye-b7fb3.appspot.com",
  messagingSenderId: "567463134819",
  appId: "1:567463134819:web:fbaa64948d4bf6a4f95a56"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);